.main_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 100px;
    padding-bottom: 50px;
}

.title_hub {
    font-family: 'NunitoSans';
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  color: #f14d03;
  margin-bottom: 30px;

}
.subtitle {
    font-family: 'Inter-Variable';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2f4858;
  
}

.searcher_wrapper {
    border-radius: 8px;
    display: flex;
    align-items: center;
    box-shadow: 0 8px 40px 0 rgba(9, 44, 76, 0.16);
    margin-bottom: 40px;
}
.input_searcher {
    outline: none;
    border: 0;
    width: 400px;

    padding-left: 20px;


    font-family: 'Inter-Variable';
    font-size: 15px;
    &::placeholder {

      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #bdbdbd;
    }
}

.button_searcher {
    background-color: #2f4858 !important;
    outline: none;
    border: 0;

    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;


font-family: 'Inter-Variable';
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 31px;
}
.controlled_bar {
    height: 59px;
    // padding: 0 0 0 24px;
  
    background-color: #ffffff;
}

.round_language {

    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    
    padding: 0px 10px;
    // border-radius: 4px;
    border-right: solid 1px #e3e3e3;
    background-color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdownmenu_custom {
    min-width: auto !important;
  }
  .custom_arrow::after {
    // display: inline-block;
    // margin-left: 0.655em;
    // margin-top: 0.15em;
    // vertical-align: 0.255em;
    content: none;
    // border-top: 0.3em solid;
    // border-right: 0.3em solid transparent;
    // border-bottom: 0;
    // border-left: 0.3em solid transparent;
  }
  .location_name {
    font-family: 'Inter-Variable';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2f4858;

    padding: 0 15px;
  }
  .location_w {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }